// Private hero

.private-hero {
  background-size: cover;
  background-position: center;
  min-height: 540px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 451px;
    align-items: center; }
  .title-main {
    font-family: 'Geometria-Bold';
    font-size: 64px;
    line-height: 81px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 177px;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 0; } } }

// Private text

.private-text {
  padding: 0 0 70px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 35px 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 290px;
    width: 100%;
    max-width: 42%;
    height: 284px;
    background-color: $color-blue;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 10px;
    width: 100%;
    max-width: 30%;
    height: 284px;
    background-color: $color-blue;
    z-index: -1;
    @media only screen and (max-width: $r-992) {
      bottom: 10%; }
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .title-secondary {
    margin-bottom: 60px;
    @media only screen and (max-width: $r-768 - 1) {
      align-items: baseline;
      margin-bottom: 18px;
      &:before {
        top: 12px; } } }
  p {
    &:last-of-type {
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 20px; } } }
  .wrapper {
    @media only screen and (max-width: $r-992) {
      margin-bottom: 30px; }
    &:last-of-type {
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 0; } }
    &:nth-of-type(even) {
      .image {
        text-align: right;
        @media only screen and (max-width: $r-768 - 1) {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            position: absolute;
            content: '';
            left: -15px;
            width: calc(100% + 30px);
            height: 194px;
            background-color: $color-blue;
            z-index: -1; } }
        img {
          max-width: 426px;
          @media only screen and (max-width: $r-992) {
            max-width: 100%; } } } }
    &:nth-of-type(odd) {
      .image {
        text-align: left;
        @media only screen and (max-width: $r-768 - 1) {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            position: absolute;
            content: '';
            left: -15px;
            bottom: -19px;
            width: 80%;
            height: 182px;
            background-color: $color-blue;
            z-index: -1; } }
        img {
          max-width: 452px;
          @media only screen and (max-width: $r-992) {
            max-width: 100%; } } }
      .text {
        padding-top: 105px;
        @media only screen and (max-width: $r-992) {
          padding-top: 0; } }
      div[class*="col-"] {
        &:first-child {
          order: 2;
          @media only screen and (max-width: $r-768 - 1) {
            order: 1; } }
        &:nth-of-type(2) {
          order: 1;
          @media only screen and (max-width: $r-768 - 1) {
            order: 2; } } } } } }

// Private include

.private-include {
  padding: 70px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 35px 0 20px 0; }
  .title-third {
    margin-bottom: 53px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 26px; } }
  .wrapper {
    padding: 155px 0;
    position: relative;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 133px 0 55px 0; }
    .bg {
      img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 60%;
        object-fit: cover;
        z-index: -1;
        @media only screen and (max-width: $r-768 - 1) {
          width: 50%; } } } }
  .slider {
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px;
      overflow: hidden; }
    .slider-transactions {
      .swiper-wrapper {
        transition-timing-function: linear!important; }
      .swiper-slide {
        width: 288px;
        .item {
          background: #FDFDFD;
          box-shadow: inset 0px -2px 0px $color-brown;
          width: 100%;
          padding: 44px 20px 35px 20px;
          .logo {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            img {
              height: 100%; } }
          h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 0;
            color: $color-blue; } } }
      .arrows {
        display: none;
        margin-top: 20px;
        @media only screen and (max-width: $r-768 - 1) {
          display: flex;
          justify-content: center; }
        .swiper-button-next,
        .swiper-button-prev {
          position: initial;
          background-image: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: white;
          border: 2px solid $color-brown;
          border-radius: 2px;
          margin: 0 15px;
          outline: none; } } } } }

// Private services

.private-services {
  @media only screen and (max-width: $r-768 - 1) {
    padding: 20px 0 0 0; }
  .title-secondary {
    @media only screen and (max-width: $r-768 - 1) {
      align-items: baseline;
      &:before {
        top: 12px; } } }
  .wrapper {
    flex-wrap: wrap;
    margin-bottom: 0;
    .item {
      width: 25%;
      background-position: center;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        height: 215px;
        background-position: revert; }
      &.active {
        width: 50%;
        @media only screen and (max-width: $r-768 - 1) {
          width: 100%;
          height: 465px;
          background-position: center; }
        &:before {
          display: none; }
        .info {
          top: 0;
          @media only screen and (max-width: $r-768 - 1) {
            top: initial;
            bottom: 45px; }
          h3 {
            max-width: 100%;
            font-size: 40px;
            line-height: 50px;
            padding-left: 77px;
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 28px;
              line-height: 35px;
              padding-left: 5px; }
            &:before {
              width: 77px;
              @media only screen and (max-width: $r-768 - 1) {
                width: 20px; } } } } } }
    .atr {
      display: none;
      margin-top: 70px;
      @media only screen and (max-width: $r-768 - 1) {
        margin: 25px 0; }
      &.active {
        display: block; } }
    .order-item {
      &:first-child {
        order: 1; }
      &:nth-of-type(3) {
        order: 2;
        @media only screen and (max-width: $r-768 - 1) {
          order: 3; } }
      &:nth-of-type(5) {
        order: 3;
        @media only screen and (max-width: $r-768 - 1) {
          order: 5; } }
      &:nth-of-type(2) {
        order: 4;
        @media only screen and (max-width: $r-768 - 1) {
          order: 2; } }
      &:nth-of-type(4) {
        order: 5;
        @media only screen and (max-width: $r-768 - 1) {
          order: 4; } }
      &:nth-of-type(6) {
        order: 6;
        @media only screen and (max-width: $r-768 - 1) {
          order: 6; } } } }
  p {
    max-width: 705px; } }

// Private features

.private-features {
  padding: 70px 0;
  position: relative;
  display: none;
  &[d-id="atr-1"],
  &[hash-id="primary-fundraising"] {
    display: block; }
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 20px 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 165px;
    width: 29%;
    height: 408px;
    background: #F7F7F7;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -38px;
    width: 29%;
    height: 626px;
    background: #F7F7F7;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .wrapper {
    align-items: center;
    margin-bottom: 176px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 80px; }
    &:last-of-type {
      margin-bottom: 0; }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0; } }
    &:nth-of-type(odd) {
      .image {
        @media only screen and (max-width: $r-768 - 1) {
          position: relative;
          &:before {
            position: absolute;
            content: '';
            right: -15px;
            bottom: -41px;
            width: 55%;
            height: 164px;
            background: #F7F7F7;
            z-index: -1; } } } }
    &:nth-of-type(even) {
      .image {
        @media only screen and (max-width: $r-768 - 1) {
          position: relative;
          &:before {
            position: absolute;
            content: '';
            left: -15px;
            bottom: -41px;
            width: 55%;
            height: 164px;
            background: #F7F7F7;
            z-index: -1; } } }
      div[class*="col-"] {
        &:first-child {
          order: 2;
          @media only screen and (max-width: $r-768 - 1) {
            order: 1; } }
        &:nth-of-type(2) {
          order: 1;
          @media only screen and (max-width: $r-768 - 1) {
            order: 2; } } } }
    .title-third {
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 20px; } }
    p {
      max-width: 578px;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0 15px; }
      &:last-of-type {
        margin-bottom: 35px; } } } }

// Private fundracing

.private-fundracing {
  padding: 160px 0 70px 0;
  display: none;
  &[d-id="atr-1"],
  &[hash-id="primary-fundraising"] {
    display: block; }
  @media only screen and (max-width: $r-768 - 1) {
    padding: 70px 0 45px 0; }
  .title-third {
    margin-bottom: 12px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 8px; } }
  p {
    font-weight: 600;
    text-align: center; }
  .slider {
    max-width: 100%;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    .swiper-slide {
      max-width: 500px;
      .item {
        height: 350px;
        background-color: #F7F7F7;
        padding: 42px 53px 15px 34px;
        @media only screen and (max-width: $r-768 - 1) {
          height: 280px;
          padding: 25px 30px 15px 20px; }
        .number {
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 32px;
          margin-bottom: 38px;
          color: $color-blue;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 41px; } }
        p {
          font-style: normal;
          font-weight: normal;
          text-align: left; } } } }
  .slider-info {
    display: flex;
    align-items: center;
    margin-top: 65px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-top: 25px;
      flex-wrap: wrap; }
    .pages {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      margin-right: 30px;
      color: $color-blue;
      @media only screen and (max-width: $r-768 - 1) {
        width: 77px;
        font-size: 18px;
        margin-right: 7px; }
      .current,
      .total {
        min-width: 34px;
        padding: 0 12px;
        @media only screen and (max-width: $r-768 - 1) {
          min-width: 24px;
          padding: 0 6px; } }
      .current {
        padding-left: 0; }
      .separator {
        width: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #D1D1D1; } } }
    .pagination {
      width: 100%;
      max-width: 422px;
      margin-right: 27px;
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        width: calc(100% - 115px); }
      .swiper-pagination {
        height: 2px;
        width: 100%;
        background-color: #E8E8E8;
        .swiper-pagination-progressbar-fill {
          background-color: $color-blue; } } }
    .arrows {
      display: flex;
      align-items: center;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        justify-content: center;
        margin-top: 20px; }
      .swiper-button-next,
      .swiper-button-prev {
        background-image: none;
        position: initial;
        margin: 0 20px;
        width: auto;
        height: auto;
        outline: none;
        &.swiper-button-disabled {
          opacity: 1;
          svg {
            path {
              fill: #D1D1D1; } } } } } } }

// Private key

.private-key {
  padding: 95px 0 140px 0;
  background-color: #F7F7F7;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 45px 0; }
  .title-third {
    text-align: left;
    margin-bottom: 43px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 26px; } }
  .image {
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 27px; } }
  ul {
    padding-left: 100px;
    margin-bottom: 0;
    @media only screen and (max-width: $r-992) {
      padding-left: 0; }
    li {
      margin-bottom: 34px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 24px; }
      &:last-of-type {
        margin-bottom: 0;
        p {
          margin-bottom: 0; } }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 2px;
        color: $color-blue;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 22px;
          line-height: 26px; } } } } }


