// Home hero

.home-hero {
  position: relative;
  min-height: 720px;
  padding: 130px 0 0 0;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 782px;
    padding: 85px 0 0 0; }
  .bg {
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &:first-child {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block; } } } }
  .title-main {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 80px;
    @media only screen and (max-width: $r-1200) {
      font-size: 60px; }
    @media only screen and (max-width: $r-992) {
      font-size: 40px; }
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; } }
  .wrapper {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $r-992) {
      flex-direction: column; }
    .item {
      min-width: 29%;
      padding: 0 30px;
      display: flex;
      align-items: center;
      position: relative;
      @media only screen and (max-width: $r-992) {
        margin-bottom: 20px;
        padding: 0 20px; }
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 3px;
        height: 90%;
        background-color: $color-brown; }
      &:first-child {
        .number {
          width: 157px;
          @media only screen and (max-width: $r-1200) {
            width: 115px; }
          @media only screen and (max-width: $r-992) {
            width: auto; } } }
      &:nth-of-type(2) {
        .number {
          min-width: 281px;
          @media only screen and (max-width: $r-1200) {
            min-width: 206px; }
          @media only screen and (max-width: $r-992) {
            min-width: auto; } } }
      &:nth-of-type(3) {
        .number {
          min-width: 170px;
          @media only screen and (max-width: $r-992) {
            min-width: auto; } } }
      .number {
        font-family: 'Geometria-Bold';
        font-style: normal;
        font-size: 65px;
        line-height: 1;
        margin-right: 25px;
        color: white;
        @media only screen and (max-width: $r-1200) {
          font-size: 48px; }
        @media only screen and (max-width: $r-992) {
          font-size: 40px;
          margin-right: 15px; } }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        margin: 0;
        color: white;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 14px;
          line-height: 16px; } } } } }

// Home partners

.home-partners {
  padding: 65px 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(23, 46, 99, 0.8);
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 20px 0; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background: #F7F7F7;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .container {
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0; } }
    .row {
      align-items: center; } }
  .text {
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px 0 15px; }
    .title-secondary {
      margin-bottom: 0;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 30px;
        &:before {
          top: 13px; } } }
    p {
      max-width: 500px; } }
  .logos {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: $r-768 - 1) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 15px 0 15px; }
    img {
      margin-left: 30px;
      @media only screen and (max-width: $r-768 - 1) {
        margin: 0 0 20px 0; }
      &:first-child {
        max-height: 27px; }
      &:nth-of-type(2) {
        max-height: 60px; } } } }

// Home platform

.home-platform {
  padding: 70px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0; }
  .container {
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0; } } }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 120px;
    height: 540px;
    width: 40%;
    background-color: $color-blue;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .title-secondary {
    margin-bottom: 50px;
    @media only screen and (max-width: $r-768 - 1) {
      max-width: 300px;
      margin-bottom: 25px;
      padding-left: 60px;
      &:before {
        width: 30px;
        left: 15px; } } }
  p {
    max-width: 710px;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px; } }
  .wrapper {
    display: flex;
    margin-bottom: 50px;
    @media only screen and (max-width: $r-992) {
      flex-wrap: wrap; }
    @media only screen and (max-width: $r-768 - 1) {
      flex-direction: column;
      margin-bottom: 25px; }
    .flex {
      width: 25%;
      position: relative;
      @media only screen and (max-width: $r-992) {
        width: 50%; }
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%; } }
    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 440px;
      background-size: cover;
      background-position: center;
      padding: 15px 30px 40px 25px;
      overflow: hidden;
      transition: .3s;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        height: 455px; }
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(23, 49, 99, 0.5), rgba(23, 49, 99, 0.5));
        opacity: 0;
        transition: .3s; }
      &.active {
        &:before {
          transition: .3s;
          opacity: 1; }
        .union {
          svg {
            transition: .3s;
            &:first-child {
              opacity: 0;
              visibility: hidden; }
            &:nth-of-type(2) {
              opacity: 1;
              visibility: visible; } } }
        .text {
          transition: .3s;
          opacity: 1;
          margin-bottom: 0; }
        .info {
          transition: .3s;
          top: -188px; } }
      img {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover; }
      .info {
        position: relative;
        top: 0;
        transition: .3s;
        h3 {
          max-width: 235px;
          min-height: 50px;
          position: relative;
          font-family: 'Geometria-Bold';
          font-style: normal;
          font-size: 20px;
          line-height: 25px;
          text-transform: uppercase;
          margin-bottom: 15px;
          color: white;
          &:before {
            position: absolute;
            content: '';
            left: -25px;
            top: 11px;
            width: 15px;
            height: 3px;
            background-color: $color-brown; } }
        p {
          position: relative;
          min-height: 42px;
          max-width: 215px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: white;
          @media only screen and (max-width: $r-768 - 1) {
            padding: 0; } } }
      .text {
        position: absolute;
        height: 188px;
        width: calc(100% - 34px);
        left: 0;
        bottom: 0;
        background: #F7F7F7;
        box-shadow: inset 0 2px 0 $color-brown;
        padding: 30px 10px 30px 30px;
        margin: 50px 0 -188px 0;
        transition: .3s;
        ul {
          li {
            position: relative;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            padding-left: 15px;
            color: $color-blue;
            &:before {
              position: absolute;
              content: '';
              left: 0;
              top: 14px;
              width: 3px;
              height: 3px;
              background-color: $color-brown;
              border-radius: 50px; } } } } }
    .union {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 68px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: $color-brown;
      border-radius: 2px 0 0 0;
      cursor: pointer;
      svg {
        position: absolute;
        transition: .3s;
        &:nth-of-type(2) {
          opacity: 0;
          visibility: hidden; } } } } }

// Home transactions

.home-transactions {
  padding: 100px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 60px 0; }
  .bg {
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 60%;
      object-fit: cover;
      @media only screen and (max-width: $r-768 - 1) {
        width: 160px; }
      &:first-child {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block; } } } }
  .title-secondary {
    margin-bottom: 60px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; } }
  .slider {
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px;
      overflow: hidden; }
    .slider-transactions {
      .swiper-wrapper {
        transition-timing-function: linear!important; }
      .swiper-slide {
        width: 288px;
        .item {
          background: #FDFDFD;
          box-shadow: inset 0px -2px 0px $color-brown;
          width: 100%;
          padding: 20px 20px 25px 20px;
          .logo {
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            img {
              height: 100%; } }
          h3,
          h4 {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 0; }
          h3 {
            color: $color-blue; }
          h4 {
            font-weight: 500;
            color: #1e44a2; } } }
      .arrows {
        display: none;
        margin-top: 20px;
        @media only screen and (max-width: $r-768 - 1) {
          display: flex;
          justify-content: center; }
        .swiper-button-next,
        .swiper-button-prev {
          position: initial;
          background-image: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: white;
          border: 2px solid $color-brown;
          border-radius: 2px;
          margin: 0 15px;
          outline: none; } } } } }

// Home story

.home-story {
  padding: 100px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 60px 0 0 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    background: #F7F7F7;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .container {
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0; } } }
  .title-secondary {
    margin-bottom: 60px;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px 0 60px;
      margin-bottom: 30px;
      &:before {
        left: 15px; } } }
  .text {
    p {
      max-width: 520px;
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 25px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 20px; } }
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0 15px; } } }
  .link {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $color-brown;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 16px;
      line-height: 19px;
      padding: 0 15px;
      margin-bottom: 30px; }
    &:hover {
      transition: .3s;
      svg {
        transform: rotate(45deg);
        @media only screen and (max-width: $r-768 - 1) {
          height: 16px; } } }
    svg {
      transition: .3s;
      transform: rotate(0);
      margin-right: 10px; } }
  .buttons {
    display: flex;
    justify-content: center;
    width: 741px;
    padding: 45px 15px;
    margin-bottom: 50px;
    background-color: $color-blue;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-768 - 1) {
      width: 100%;
      flex-direction: column;
      padding: 30px 15px;
      margin-bottom: 0; }
    a {
      margin: 0 15px;
      @media only screen and (max-width: $r-768 - 1) {
        margin: 0 0 20px 0;
        &:last-of-type {
          margin-bottom: 0; } } } }
  img {
    height: 100%!important;
    object-fit: cover;
    @media only screen and (max-width: $r-768 - 1) {
      height: auto!important; } } }

// Get in touch

.get-in-touch {
  display: flex;
  align-items: center;
  padding: 120px 0;
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    content: '';
    right: 0;
    width: 40%;
    height: calc(100% - 120px);
    background-color: $color-blue;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .row {
    align-items: center; }
  .touch-btn {
    text-align: right;
    padding-right: 50px;
    @media only screen and (max-width: $r-768 - 1) {
      text-align: center;
      padding-right: 0; } }
  .text {
    .title-secondary {
      margin-bottom: 30px; } } }
