// Colors

$color-brown: #C7B678;
$color-blue: #173163;

// Global

html,
body {
  overflow-x: hidden; }

body {
  font-family: 'Barlow', sans-serif!important;
  .container {
    max-width: 1240px; } }

img {
  max-width: 100%; }

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: $color-blue;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 18px;
    line-height: 26px; }
  b, strong {
    font-weight: 600; } }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.title-main {
  font-family: 'Geometria-Bold';
  font-style: normal;
  font-size: 76px;
  line-height: 96px;
  color: white;
  @media only screen and (max-width: $r-992) {
    font-size: 40px;
    line-height: 50px; } }

.title-secondary {
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'Geometria-Bold';
  font-style: normal;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
  padding-left: 106px;
  color: $color-blue;
  @media only screen and (max-width: $r-992) {
    font-size: 28px;
    line-height: 35px; }
  @media only screen and (max-width: $r-768 - 1) {
    padding-left: 45px; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    width: 76px;
    height: 3px;
    background-color: $color-brown;
    @media only screen and (max-width: $r-768 - 1) {
      width: 30px; } } }

.title-third {
  font-family: 'Geometria-Medium';
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: $color-blue;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 26px;
    line-height: 32px; } }


.btn-transparant {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 290px;
  height: 60px;
  border: 2px solid $color-brown;
  background-color: transparent;
  border-radius: 2px;
  font-family: 'Geometria-Bold';
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
  transition: .3s;
  color: $color-brown;
  &:hover {
    transition: .3s;
    text-decoration: none;
    background-color: $color-brown;
    color: white; } }

.btn-brown {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 290px;
  height: 60px;
  border: 2px solid $color-brown;
  background-color: $color-brown;
  border-radius: 2px;
  font-family: 'Geometria-Medium';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
  transition: .3s;
  color: white;
  &:hover {
    transition: .3s;
    text-decoration: none;
    background-color: white;
    border-color: white;
    color: $color-blue; } }

// Breadcrumbs

.breadcrumbs {
  padding: 35px 0;
  @media only screen and (max-width: $r-768 - 1) {
    display: none; }
  ul {
    display: flex;
    align-items: center;
    li {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 0 10px;
      position: relative;
      color: $color-blue;
      &:before {
        position: absolute;
        content: '/';
        right: -5px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-brown; }
      &:first-child {
        padding-left: 0; }
      &:last-of-type {
        &:before {
          display: none; } }
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-blue; } } } }

// Coming soon

.coming-soon {
  padding: 320px 0 250px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 100px 0; }
  .text {
    padding-bottom: 42px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 83px;
      height: 3px;
      background-color: $color-brown; }
    .title-secondary {
      margin-bottom: 27px;
      padding-left: 0;
      &:before {
        display: none; } }
    p {
      font-family: 'Barlow', sans-serif;
      font-style: normal;
      font-size: 36px;
      font-weight: 500;
      line-height: 45px;
      text-transform: capitalize;
      margin-bottom: 0;
      color: $color-blue;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 26px; } } } }

// Modal contact

.modal-contact {
  .modal-dialog {
    max-width: 876px;
    .modal-content {
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 0;
        position: relative;
        .btn-close {
          position: absolute;
          right: 24px;
          top: 17px; } } }
    .wrapper {
      display: flex;
      @media only screen and (max-width: $r-768 - 1) {
        flex-direction: column; }
      .left {
        width: 40%;
        padding: 180px 0 100px 0;
        background-color: #FAFAFA;
        @media only screen and (max-width: $r-768 - 1) {
          width: 100%;
          padding: 50px 0 25px 0; }
        .title-secondary {
          align-items: baseline;
          max-width: 310px;
          padding-left: 70px;
          &:before {
            top: 20px;
            width: 60px;
            @media only screen and (max-width: $r-768 - 1) {
              top: 15px; } } } }
      .right {
        width: 60%;
        padding: 180px 15px 100px 47px;
        background-color: white;
        @media only screen and (max-width: $r-768 - 1) {
          width: 100%;
          padding: 50px 15px 25px 15px; }
        .wpforms-container {
          width: 100%;
          .wpforms-field {
            padding: 0; } }
        form {
          width: 100%;
          max-width: 358px;
          @media only screen and (max-width: $r-768 - 1) {
            max-width: 100%; }
          input[type="text"],
          input[type="email"],
          textarea {
            width: 100%;
            height: 37px;
            border: 0;
            border-bottom: 1px solid #E1E6F3;
            margin-bottom: 22px;
            resize: none;
            outline: none;
            appearance: none;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
            color: $color-blue;
            &::placeholder {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;
              text-transform: capitalize;
              color: $color-blue; } }
          button {
            width: 100%;
            margin: 28px 0 0 0;
            border: 0;
            border: 1px solid $color-brown;
            background-color: $color-brown;
            text-transform: uppercase;
            color: white;
            &:hover {
              transition: .3s;
              background-color: white;
              border-color: $color-brown;
              color: $color-blue; } } } } } } }

// Elementor

body {
  .elementor-widget:not(:last-child) {
    margin-bottom: 0; } }

