body {
  &.rtl {
    direction: rtl;
    text-align: right;
    .title-secondary {
      padding: 0 106px 0 0;
      &:before {
        left: initial;
        right: 0; } }
    .home-hero {
      .wrapper {
        .item {
          .number {
            margin: 0 0 0 25px; } } } }
    .home-platform {
      &:before {
        right: initial;
        left: 0; }
      .wrapper {
        .item {
          .info {
            h3 {
              left: initial;
              right: 0;
              &:before {
                left: initial;
                right: -30px; } } }
          .text {
            ul {
              li {
                padding: 0 15px 0 0;
                &:before {
                  left: initial;
                  right: 0; } } } } }
        .union {
          right: initial;
          left: 0; } } }
    .home-transactions {
      .bg {
        img {
          left: initial;
          right: 0; } } }
    .home-story {
      &:before {
        right: initial;
        left: 0; }
      .link {
        svg {
          margin: 0 0 0 10px; } } }
    .footer {
      .wrapper {
        .item {
          margin: 0 64px 60px 0;
          &:first-of-type {
            margin-right: 0; }
          h3 {
            padding: 0 48px 0 0;
            &:before {
              left: initial;
              right: 0; } } } } }
    .coming-soon {
      .text {
        .title-secondary {
          padding: 0 0 0 106px; }
        &:before {
          left: initial;
          right: 0; } } }
    .breadcrumbs {
      ul {
        li {
          &:first-child {
            padding: 0 0 0 10px; }
          &:before {
            right: initial;
            left: -5px; } } } }
    .brokerage-text {
      &:before {
        left: initial;
        right: 0; } }
    .private-services {
      .wrapper {
        .item {
          &.active {
            .info {
              h3 {
                padding: 0 77px 0 0; } } } } } }
    .private-text {
      &:before {
        right: initial;
        left: 0; }
      &:after {
        left: initial;
        right: 0; } }
    .investment-services {
      .wrapper {
        .item {
          &.active {
            left: initial;
            right: 0; } } }
      ul {
        li {
          padding: 0 20px 0 0;
          &:before {
            left: initial;
            right: 0; } } } }
    .investment-logos {
      .wrapper {
        .bg {
          img {
            left: initial;
            right: 0; } } } }
    .investment-companies {
      &:before {
        right: initial;
        left: 0; } }
    .private-features {
      &:before {
        right: initial;
        left: 0; }
      &:after {
        left: initial;
        right: 0; } }
    .private-fundracing {
      .slider {
        .swiper-slide {
          .item {
            p {
              text-align: right; } } } }
      .slider-info {
        .pages {
          margin: 0 0 0 30px;
          .current {
            padding: 0 0 0 12px; } }
        .arrows {
          .swiper-button-prev {
            order: 2; }
          .swiper-button-next {
            order: 1; } } } }
    .private-key {
      .title-third {
        text-align: right; }
      ul {
        padding: 0 100px 0 0; } }
    .research-features {
      &:before {
        right: initial;
        left: 0; } } } }
