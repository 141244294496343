// Header

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 0;
  z-index: 1;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 15px 0; }
  &-blue {
    &.opened {
      nav {
        background-color: white!important; } }
    nav {
      ul {
        li {
          a {
            padding-bottom: 11px;
            position: relative;
            color: $color-blue!important; } } } } }
  .row {
    align-items: flex-end;
    @media only screen and (max-width: $r-992) {
      align-items: center; } }
  div[class*="col-"] {
    position: initial; }
  .logo {
    position: relative;
    z-index: 1;
    img {
      height: 50px;
      @media only screen and (max-width: $r-768 - 1) {
        height: 30px; } } }
  .hamburger {
    display: none;
    text-align: right;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      display: block; }
    svg {
      &:nth-of-type(2) {
        display: none; } } }
  &.opened {
    nav {
      display: flex;
      justify-content: flex-start;
      background-color: $color-blue;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      height: 100%;
      padding: 100px 15px 30px 15px;
      ul {
        flex-direction: column;
        li {
          flex-wrap: wrap;
          margin: 0 0 30px 0;
          justify-content: flex-start;
          a {
            font-size: 20px;
            line-height: 1;
            padding-bottom: 10px;
            &:before {
              display: none; }
            &:hover {
              &:before {
                display: block; } } }
          ul {
            position: initial; } } } }
    .hamburger {
      svg {
        &:first-child {
          display: none; }
        &:nth-of-type(2) {
          display: inline-flex; } } } }
  nav {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: $r-992) {
      display: none; }
    ul {
      display: flex;
      margin: 0;
      @media only screen and (max-width: $r-992) {
        width: 100%; }
      li {
        display: flex;
        justify-content: center;
        margin: 0 15px;
        position: relative;
        @media only screen and (max-width: $r-1200) {
          margin: 0 5px; }
        &:hover {
          transition: .3s;
          a {
            &:before {
              width: 100%;
              left: 0; } }
          ul {
            opacity: 1;
            z-index: 1;
            visibility: visible;
            @media only screen and (max-width: $r-992) {
              display: flex; } } }
        &:last-of-type {
          margin-right: 0; }
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          position: relative;
          padding-bottom: 11px;
          transition: .3s;
          color: white;
          &:before {
            display: none;
            position: absolute;
            content: '';
            left: 50%;
            bottom: 1px;
            width: 0;
            height: 2px;
            transition: .3s;
            background-color: $color-brown; }
          &:hover {
            text-decoration: none;
            transition: .3s;
            color: $color-brown!important;
            &:before {
              width: 100%;
              left: 0; } } }
        ul {
          display: flex;
          flex-direction: column;
          min-width: 206px;
          position: absolute;
          top: 29px;
          background-color: rgba(23, 46, 99, 0.65);
          border-top: 2px solid $color-brown;
          padding: 24px 20px 30px 40px;
          opacity: 0;
          z-index: -1;
          visibility: hidden;
          transition: .3s;
          @media only screen and (max-width: $r-992) {
            border-top: 0;
            min-width: 100%;
            background-color: transparent;
            padding: 20px;
            display: none;
            opacity: 1;
            z-index: 1;
            visibility: visible; }
          li {
            margin: 0 0 15px 0;
            justify-content: flex-start;
            &:last-of-type {
              margin-bottom: 0; }
            a {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              text-transform: capitalize;
              padding-bottom: 0;
              color: white;
              @media only screen and (max-width: $r-992) {
                font-size: 16px;
                line-height: 19px; }
              &:before {
                display: none; } } } } } } } }
