// Footer

.footer {
  padding: 60px 0 20px 0;
  background-color: $color-blue;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0 15px 0; }
  .logo {
    @media only screen and (max-width: $r-992) {
      margin-bottom: 40px; }
    img {
      height: 62px;
      @media only screen and (max-width: $r-768 - 1) {
        height: 50px; } } }
  .wrapper {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: $r-992) {
      justify-content: flex-start; }
    @media only screen and (max-width: $r-768 - 1) {
      flex-wrap: wrap; }
    .item {
      margin: 0 0 60px 64px;
      @media only screen and (max-width: $r-992) {
        margin: 0 15px 60px 0; }
      @media only screen and (max-width: $r-768 - 1) {
        margin: 0 20px 30px 0; }
      &:first-of-type {
        margin-left: 0; }
      h3 {
        position: relative;
        font-style: normal;
        font-family: 'Geometria-Bold';
        font-size: 20px;
        line-height: 25px;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-left: 48px;
        color: white;
        @media only screen and (max-width: $r-768 - 1) {
          padding-left: 40px; }
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 11px;
          width: 38px;
          height: 2px;
          background-color: $color-brown;
          @media only screen and (max-width: $r-768 - 1) {
            width: 30px; } } }
      p,
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: white; }
      p {
        max-width: 210px; }
      a {
        transition: .3s;
        display: block;
        &:hover {
          transition: .3s;
          color: $color-brown; } }
      .socials {
        ul {
          padding: 0;
          margin: 0;
          li {
            margin-right: 15px;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border: 1px solid white;
              svg {
                height: 28px; } } } } } } }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #546386;
    padding: 20px 0 0 0;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 0;
      color: #868EA2;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 10px; } }
    .created {
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-left: 5px; } } } } }
