@font-face {
  font-family: 'Geometria-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Thin'), url('../fonts/Geometria-Thin.woff') format('woff'); }

@font-face {
  font-family: 'Geometria-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Light'), url('../fonts/Geometria-Light.woff') format('woff'); }

@font-face {
  font-family: 'Geometria-Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Heavy'), url('../fonts/Geometria-Heavy.woff') format('woff'); }

@font-face {
  font-family: 'Geometria-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Medium'), url('../fonts/Geometria-Medium.woff') format('woff'); }


@font-face {
  font-family: 'Geometria-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Bold'), url('../fonts/Geometria-Bold.woff') format('woff'); }

@font-face {
  font-family: 'Geometria-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraBold'), url('../fonts/Geometria-EaxtraBold.woff') format('woff'); }
