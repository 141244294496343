.page-id-302 {
  .private-text {
    &:after {
      display: none; } } }

// Research features

.research-features {
  padding: 130px 0 70px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 130px;
    width: 40%;
    height: 386px;
    background-color: #F7F7F7;
    @media only screen and (max-width: $r-768 - 1) {
      width: 100%;
      top: 160px; } }
  .container {
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0; } }
  .text {
    margin-bottom: 50px;
    max-width: 690px;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px;
      margin-bottom: 60px; }
    .title-secondary {
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 18px; } } }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: $r-768 - 1) {
      flex-direction: column; }
    .item {
      background-size: cover;
      background-position: center;
      padding: 30px;
      height: 240px;
      width: 25%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        height: 250px; }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        color: white; } } } }
