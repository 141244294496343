.page-id-171 {
  .private-text {
    &:after {
      display: none; } } }

// Brokerage text

.brokerage-text {
  position: relative;
  padding: 0 0 60px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 45px 0 80px 0; }
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 37%;
    height: 284px;
    background: $color-blue;
    @media only screen and (max-width: $r-768 - 1) {
      bottom: 60px;
      width: 75%;
      height: 182px; } }
  .row {
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        &:first-child {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } } }
  .text {
    .title-secondary {
      align-items: flex-start;
      &:before {
        top: 22px; } } }
  .image {
    text-align: center;
    img {
      max-width: 450px;
      @media only screen and (max-width: $r-992) {
        max-width: 100%; } } } }
