// Investment hero

.investment-hero {
  background-size: cover;
  background-position: center;
  min-height: 540px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 451px;
    align-items: center; }
  .title-main {
    font-family: 'Geometria-Bold';
    font-size: 64px;
    line-height: 81px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 177px;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 0; } } }

// Investment text

.investment-text {
  .title-secondary {
    max-width: 1030px; }
  .wrapper {
    &:nth-of-type(odd) {
      .text {
        padding-top: 140px;
        @media only screen and (max-width: $r-768 - 1) {
          padding-top: 0; } } } } }

// Investment services

.investment-services {
  @media only screen and (max-width: $r-768 - 1) {
    padding: 20px 0; }
  .title-secondary {
    @media only screen and (max-width: $r-768 - 1) {
      align-items: baseline;
      &:before {
        top: 12px; } } }
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0;
    position: relative;
    .item {
      width: 33%;
      height: 150px;
      background-position: right;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        height: 150px;
        background-position: revert; }
      .union {
        width: 50px;
        height: 50px;
        @media only screen and (max-width: $r-768 - 1) {
          width: 68px;
          height: 68px; }
        svg {
          width: 16px;
          @media only screen and (max-width: $r-768 - 1) {
            width: 22px; } } }
      &.active {
        position: absolute;
        left: 0;
        width: 67%;
        height: 450px;
        @media only screen and (max-width: $r-768 - 1) {
          position: relative;
          width: 100%;
          height: 465px;
          background-position: center; }
        &:before {
          display: none; }
        .info {
          top: initial;
          bottom: 30px;
          @media only screen and (max-width: $r-768 - 1) {
            top: initial;
            bottom: 45px; }
          h3 {
            max-width: 100%;
            font-size: 40px;
            line-height: 50px;
            padding-left: 77px;
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 28px;
              line-height: 35px;
              padding-left: 5px; }
            &:before {
              width: 77px;
              @media only screen and (max-width: $r-768 - 1) {
                width: 20px; } } } } } }
    .atr {
      display: none;
      width: 100%;
      margin-top: 50px;
      @media only screen and (max-width: $r-768 - 1) {
        margin: 25px 0; }
      &.active {
        display: block; } }
    .order-item {
      &:first-child {
        order: 1; }
      &:nth-of-type(3) {
        order: 2;
        @media only screen and (max-width: $r-768 - 1) {
          order: 3; } }
      &:nth-of-type(5) {
        order: 3;
        @media only screen and (max-width: $r-768 - 1) {
          order: 5; } }
      &:nth-of-type(7) {
        order: 4;
        @media only screen and (max-width: $r-768 - 1) {
          order: 7; } }
      &:nth-of-type(2) {
        order: 5;
        @media only screen and (max-width: $r-768 - 1) {
          order: 2; } }
      &:nth-of-type(4) {
        order: 5;
        @media only screen and (max-width: $r-768 - 1) {
          order: 4; } }
      &:nth-of-type(6) {
        order: 6;
        @media only screen and (max-width: $r-768 - 1) {
          order: 6; } }
      &:nth-of-type(8) {
        order: 8;
        @media only screen and (max-width: $r-768 - 1) {
          order: 8; } } } }
  p {
    max-width: 705px; }
  ul {
    margin-bottom: 25px;
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      position: relative;
      padding-left: 20px;
      color: $color-blue;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 12px;
        width: 4px;
        height: 4px;
        background-color: $color-brown;
        border-radius: 50%; }
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 18px;
        line-height: 26px; } } } }

// Investment logos

.investment-logos {
  padding: 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding-top: 40px; }
  .text {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 44px;
    p {
      max-width: 415px;
      font-family: 'Geometria-Medium';
      font-style: normal;
      font-size: 20px;
      line-height: 25px;
      text-transform: uppercase;
      @media only screen and (max-width: $r-768 - 1) {
        text-align: center; } }
    &.show-desctop {
      @media only screen and (max-width: $r-768 - 1) {
        display: none!important; } }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: block;
        margin-bottom: 25px; } } }
  .wrapper {
    padding: 0 0 70px 0;
    position: relative;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 60px 0; }
    .bg {
      img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 60%;
        object-fit: cover;
        z-index: -1;
        @media only screen and (max-width: $r-768 - 1) {
          width: 50%; } } }
    .slider {
      .item {
        padding: 35px 20px 30px 20px!important;
        margin-bottom: 30px;
        img {
          max-width: 65%;
          max-height: 78px;
          height: auto!important; } } } } }

// Investment companies

.investment-companies {
  padding: 100px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 0 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 40%;
    background-color: #F7F7F7;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  div[class*="col-"] {
    padding: 0; }
  .text {
    max-width: 615px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px;
      padding: 0 15px; } }
  .logos {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: $r-768 - 1) {
      background-color: #F7F7F7;
      padding: 40px 15px; }
    img {
      margin: 0 25px;
      max-height: 64px; } } }
