// About hero

.about-hero {
  background-size: cover;
  background-position: center;
  min-height: 540px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 451px;
    align-items: center; }
  .title-main {
    font-family: 'Geometria-Bold';
    font-size: 64px;
    line-height: 81px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 177px;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 0; } } }

// About story

.about-story {
  position: relative;
  padding: 0 0 120px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 50px 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 250px;
    height: 310px;
    width: 42%;
    background-color: $color-blue;
    @media only screen and (max-width: $r-992) {
      top: 7%; }
    @media only screen and (max-width: $r-768 - 1) {
      height: 182px;
      width: 80%;
      bottom: 30px;
      top: initial; } }
  .text {
    .title-secondary {
      margin-bottom: 60px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 18px; } }
    p {
      max-width: 500px;
      @media only screen and (max-width: $r-768 - 1) {
        &:last-of-type {
          margin-bottom: 30px; } } } } }

// About clients

.about-clients {
  padding: 120px 0 60px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0 30px 0; }
  &:before {
    position: absolute;
    content: '';
    width: 33%;
    height: 696px;
    left: 0;
    top: 20px;
    background: #F7F7F7;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      height: 395px;
      width: 80%;
      top: 10px; } }
  &:after {
    position: absolute;
    content: '';
    width: 33%;
    height: 390px;
    left: 0;
    bottom: 155px;
    background: #F7F7F7;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      height: 164px;
      width: 50%;
      bottom: 500px; } }
  .text {
    .title-secondary {
      margin-bottom: 50px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 20px; } } }
  .image {
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 80px; } }
  .wrapper {
    margin-bottom: 90px;
    align-items: center;
    z-index: 1;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 40px; }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0;
        .text {
          padding: 0 15px; } } }
    &:last-of-type {
      margin-bottom: 0; }
    &:nth-of-type(even) {
      div[class*="col-"] {
        &:first-child {
          order: 2;
          @media only screen and (max-width: $r-768 - 1) {
            order: 1; } }
        &:nth-of-type(2) {
          order: 1;
          @media only screen and (max-width: $r-768 - 1) {
            order: 2; } } } }
    &:nth-of-type(3) {
      .image {
        position: relative;
        z-index: 1;
        &:before {
          position: absolute;
          content: '';
          right: -60px;
          bottom: -60px;
          width: 418px;
          height: 390px;
          background: #F7F7F7;
          z-index: -1;
          @media only screen and (max-width: $r-768 - 1) {
            right: 0;
            bottom: -35px;
            width: 45%;
            height: 164px; } } } }
    .text {
      padding-left: 40px;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: $r-768 - 1) {
        padding-left: 0;
        position: relative;
        z-index: 2; }
      .title-third {
        margin-bottom: 29px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 20px; } } } } }

// About values

.about-values {
  padding: 60px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 30px 0; }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 40%;
    background-color: $color-blue;
    height: 285px;
    width: 50%;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      top: initial;
      bottom: 15%;
      width: 100%;
      height: 130px; } }
  .text {
    .title-secondary {
      margin-bottom: 60px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 20px; } } }
  .wrapper {
    .text {
      max-width: 500px;
      p {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 40px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 25px; }
        b, strong {
          font-weight: 600; } }
      h3 {
        font-size: 26px;
        font-weight: bold;
        line-height: 31px;
        margin-bottom: 5px;
        color: $color-blue;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 22px;
          line-height: 26px; } } }
    .image {
      text-align: right;
      img {
        max-height: 600px; } } } }


// About team

.about-team {
  padding: 60px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 30px 0; }
  .text {
    .title-secondary {
      margin-bottom: 60px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 30px; } } }
  .wrapper {
    .item {
      display: inline-flex;
      position: relative;
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768 - 1) {
        margin: 0 auto 14px auto;
        width: 138px;
        display: block; }
      &.opened {
        transition: .3s;
        .item-avatar {
          &:before {
            visibility: hidden;
            opacity: 0;
            z-index: -1; } }
        .info {
          visibility: hidden;
          opacity: 0;
          z-index: -1; } }
      &:hover {
        transition: .3s;
        .item-avatar {
          &:before {
            visibility: hidden;
            opacity: 0;
            z-index: -1; } }
        .info {
          visibility: hidden;
          opacity: 0;
          z-index: -1; } }
      &-avatar {
        height: 288px;
        position: relative;
        @media only screen and (max-width: $r-768 - 1) {
          height: 138px;
          width: 100%; }
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(180deg, rgba(23, 49, 99, 0) 0%, rgba(23, 49, 99, 0.3) 53.44%, rgba(23, 49, 99, 0.8) 100%);
          opacity: 1;
          visibility: visible;
          z-index: 1;
          transition: .3s; }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
      .info {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 30px;
        visibility: visible;
        opacity: 1;
        z-index: 2;
        transition: .3s;
        @media only screen and (max-width: $r-768 - 1) {
          padding: 10px; } }
      &-name,
      &-position {
        font-style: normal;
        line-height: 24px;
        color: white; }
      &-name {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 5px;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 2px; } }
      &-position {
        font-size: 18px;
        font-weight: 400;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 12px;
          line-height: 14px; } }
      &-text {
        display: none; } }
    .person-info {
      padding: 30px 0 60px 0;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 30px 0; }
      .top {
        position: relative;
        padding-left: 100px;
        margin-bottom: 27px;
        @media only screen and (max-width: $r-768 - 1) {
          padding-left: 45px;
          margin-bottom: 18px; }
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 15px;
          width: 75px;
          height: 3px;
          background-color: $color-brown;
          @media only screen and (max-width: $r-768 - 1) {
            width: 30px;
            top: 20px; } }
        .person-name,
        .person-position {
          font-style: normal;
          line-height: 24px;
          text-transform: uppercase;
          color: $color-blue; }
        .person-name {
          font-family: 'Geometria-Bold';
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px; }
        .person-position {
          font-size: 18px;
          font-weight: 400;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 16px; } } }
      .person-text {
        max-width: 816px; } } }
  .person-append {
    display: none; } }
